export const highlightImgVideoList = {
  'A': [
    {
      subCategoryCode: 'D1',
      isFullScore: false,
      title: 'IQ. Drive驾驶辅助',
      titleEn: 'IQ.Drive Driver assist',
      describe: [
        'ACC全速域自适应巡航系统，自动保持车距车速。',
        'Lane Assist车道保持辅助系统，偏离车道及时修正。',
        'Side Assist变道辅助系统，盲区监测，变道安全。'
      ],
      describeEn: [
        'ACC:ACC full speed adaptive cruise system, automatically maintain vehicle distance speed.',
        'LKA:Lane Assist The lane keeping assist system,Correct the deviation from the lane in time.',
        'SWA:Side Assist lane change assistance system,Blind area monitoring, lane change safety.'
      ],
      imgSrc: require('./imgs/highlight1/img3.png'),
      videoUrl: 'https://ei-scrmsales-cos-uat-1316407986.cos.ap-shanghai.myqcloud.com/mot-tdr/Assisted%20Driving/Video/ACC.mp4?q-sign-algorithm=sha1&q-ak=AKIDcBLpGrVEveQEBKaq1ER7kLC604ESl7ne&q-sign-time=1724227021;2588140621&q-key-time=1724227021;2588140621&q-header-list=&q-url-param-list=&q-signature=948981aac1d5aedcb5ecd340c4fe30a18ef65faf'
    },
    {
      subCategoryCode: 'D2',
      isFullScore: false,
      title: 'Travel Assist 3.0全旅程驾驶辅助系统',
      titleEn: 'Travel Assist 3.0',
      describe: ['操作便捷，安全可靠'],
      describeEn: [
        'Easy to operate',
        'Safe and reliable' ],
      imgSrc: require('./imgs/highlight1/img1.png'),
      videoUrl: 'https://ei-scrmsales-cos-uat-1316407986.cos.ap-shanghai.myqcloud.com/mot-tdr/Assisted%20Driving/Video/Travel%20assist%203.0.mp4?q-sign-algorithm=sha1&q-ak=AKIDcBLpGrVEveQEBKaq1ER7kLC604ESl7ne&q-sign-time=1724227030;2588140630&q-key-time=1724227030;2588140630&q-header-list=&q-url-param-list=&q-signature=88f451e40c748f9d70b41c68c82161070d360756'
    },
    {
      subCategoryCode: 'D3',
      isFullScore: false,
      title: '交互式变道辅助系统',
      titleEn: 'Trigger lane change',
      describe: ['轻拨转向自动变道'],
      describeEn: ['Turn to automatically change lanes'],
      imgSrc: require('./imgs/highlight1/img2.png'),
      videoUrl: 'https://ei-scrmsales-cos-uat-1316407986.cos.ap-shanghai.myqcloud.com/mot-tdr/Assisted%20Driving/Video/Trigger%20lane%20change.mp4?q-sign-algorithm=sha1&q-ak=AKIDcBLpGrVEveQEBKaq1ER7kLC604ESl7ne&q-sign-time=1724227048;2588140648&q-key-time=1724227048;2588140648&q-header-list=&q-url-param-list=&q-signature=9f61efe9ee82a93ed3eba6d5daf23c0f476ba95e'
    }
  ],
  'B': [
    {
      subCategoryCode: 'B1',
      isFullScore: false,
      title: 'AR HUD增强现实平视系统',
      titleEn: 'AR-HUD',
      describe: [
        '近端抬头显示区域显示时速/车道线/交通标识/导航路径，关键信息尽收眼底。',
        '远端增强现实区域显示导航方向/目的地/ACC跟车距离，实景指示实用科幻。'
      ],
      describeEn: [
        'The near-end head-up display area displays speed/lane lines/traffic signs/navigation paths, and key information is in full view',
        'he remote augmented reality area shows navigation directions/destinations /ACC distance to the car, and the reality indicates practical sci-fi'
      ],
      imgSrc: require('./imgs/highlight2/img2.png'),
      videoUrl: 'https://ei-scrmsales-cos-uat-1316407986.cos.ap-shanghai.myqcloud.com/mot-tdr/Smart%20Cockpit/AR%20HUD.mp4?q-sign-algorithm=sha1&q-ak=AKIDcBLpGrVEveQEBKaq1ER7kLC604ESl7ne&q-sign-time=1724227635;2588141235&q-key-time=1724227635;2588141235&q-header-list=&q-url-param-list=&q-signature=7851a38a0be36e688833baae93223b23739ed0fa'
    },
    {
      subCategoryCode: 'B2',
      isFullScore: false,
      title: '15英寸2K高清中控屏',
      titleEn: '15 ABT with new HMI In-car APP',
      describe: [
        '悬浮中控屏设计，时尚瞩目。',
        '2240*1260 高清分辨率，清晰大屏。',
        '全新HMI设计 ，无忧交互。'
      ],
      describeEn: [
        'Suspension center control screen design, fashion attention',
        '2,240*1,260 HD resolution, clear and large screen',
        'New HMI design, hassle-free interaction',
      ],
      imgSrc: require('./imgs/highlight2/img1.png'),
      videoUrl: 'https://ei-scrmsales-cos-uat-1316407986.cos.ap-shanghai.myqcloud.com/mot-tdr/Smart%20Cockpit/15%20ABT%20with%20new%20HMI%20In-car%20APP.mp4?q-sign-algorithm=sha1&q-ak=AKIDcBLpGrVEveQEBKaq1ER7kLC604ESl7ne&q-sign-time=1724227622;2588141222&q-key-time=1724227622;2588141222&q-header-list=&q-url-param-list=&q-signature=1d5cabcf069b70b42e196f41a5fa99f101bec90d'
    },
    {
      subCategoryCode: 'B4',
      isFullScore: false,
      title: 'harman/kardon®沉浸Hifi音响系统',
      titleEn: 'Harmon Kardon',
      describe: [
        '包括4个低音、4个高音、3个中音和1个低音炮。',
        'Fraunhofer Sonamic 环绕声算法。',
        '支持四种音效模式。',
        'harman/kardon®专属扬声器罩盖。'
      ],
      describeEn: [
        'Includes 4 bass, 4 treble, 3 mids and 1 subwoofer',
        'Fraunhofer Sonamic surround sound algorithm',
        'Four sound modes are supported',
        'harman/kardon® exclusive speaker cover',
      ],
      imgSrc: require('./imgs/highlight2/img4.png')
    },
    {
      subCategoryCode: 'B5',
      isFullScore: false,
      title: '驾驶员注意力/疲劳监测',
      titleEn: 'Fatigue detection',
      describe: ['通过内置摄像头监测驾驶员状态，实现驾驶员注意力分散警告、疲劳警告'],
      describeEn: ['The built-in camera monitors the driver\'s status, and realizes the driver\'s attention distraction warning and fatigue warning'],
      imgSrc: require('./imgs/highlight2/img3.png'),
      videoUrl: 'https://ei-scrmsales-cos-uat-1316407986.cos.ap-shanghai.myqcloud.com/mot-tdr/Smart%20Cockpit/Fatigue%20detection.mp4?q-sign-algorithm=sha1&q-ak=AKIDcBLpGrVEveQEBKaq1ER7kLC604ESl7ne&q-sign-time=1724227645;2588141245&q-key-time=1724227645;2588141245&q-header-list=&q-url-param-list=&q-signature=afaf4b9ea777e7d4ca9c94db7acbe011b2eb75a2'
    }
  ],
  'C': [
    {
      subCategoryCode: 'C1',
      isFullScore: false,
      title: '手机远程操控',
      titleEn: 'APP remote control',
      describe: [
        '可通过手机小组件一键完成“一键备车”，“一键融雪”，“急速冷却”。',
        '可通过手机APP远程设置空调。',
        '可通过手机APP远程设置充电。',
        '搭载MDK数字钥匙车型还可实现控制车窗、尾门等。'
      ],
      describeEn: [
        'Complete "one-click backup", "one-click snow melting", "rapid cooling" through the mobile phone widget.',
        'Remotely set the air conditioner through the mobile APP.',
        'Charging is set remotely through the mobile APP.',
        'Models equipped with MDK digital keys can also control Windows, tail doors, etc.'
      ],
      imgSrc: require('./imgs/highlight3/img1.png'),
      videoUrl: 'https://ei-scrmsales-cos-uat-1316407986.cos.ap-shanghai.myqcloud.com/mot-tdr/Connectivity/Video/APP%20remote%20control.mp4?q-sign-algorithm=sha1&q-ak=AKIDcBLpGrVEveQEBKaq1ER7kLC604ESl7ne&q-sign-time=1724227428;2588141028&q-key-time=1724227428;2588141028&q-header-list=&q-url-param-list=&q-signature=e7392d94b67bf5e2c7e3ca13c95c8ba29c69b9e4'
    },
    {
      subCategoryCode: 'C2',
      isFullScore: false,
      title: 'AI智能伙伴',
      titleEn: 'Avatar',
      describe: [
        '支持个性化捏脸，打造您的专属形象。',
        '支持声音克隆，定制专属语音包。',
        '可担任日程助理角色，未来行程即时提醒。'
      ],
      describeEn: [
        'Support personalized face pinching to create your exclusive image',
        'Support voice cloning, custom voice package',
        'Serve as schedule assistant and provide immediate reminder of future itinerary'
      ],
      imgSrc: require('./imgs/highlight3/img4.png'),
      videoUrl: 'https://ei-scrmsales-cos-uat-1316407986.cos.ap-shanghai.myqcloud.com/mot-tdr/Connectivity/Video/Avatar.mp4?q-sign-algorithm=sha1&q-ak=AKIDcBLpGrVEveQEBKaq1ER7kLC604ESl7ne&q-sign-time=1724227439;2588141039&q-key-time=1724227439;2588141039&q-header-list=&q-url-param-list=&q-signature=fe45284a3a58acbdb683ce6c07be077ea9dc0c27'
    },
    {
      subCategoryCode: 'C3',
      isFullScore: false,
      title: '语音交互',
      titleEn: 'LLM',
      describe: [
        '搭载大语言模型，爱车秒变贴心伙伴',
        '支持多种方言',
        '支持多指令控制',
        '部分场景可免唤醒',
      ],
      describeEn: [
        'Equipped with a large language model, car seconds become intimate partners.',
        'Multiple dialects supported',
        'Support multi-instruction control',
        'Some scenarios do not wake up',
      ],
      imgSrc: require('./imgs/highlight3/img5.png'),
      videoUrl: 'https://ei-scrmsales-cos-uat-1316407986.cos.ap-shanghai.myqcloud.com/mot-tdr/Connectivity/Video/LLM.mp4?q-sign-algorithm=sha1&q-ak=AKIDcBLpGrVEveQEBKaq1ER7kLC604ESl7ne&q-sign-time=1724227456;2588141056&q-key-time=1724227456;2588141056&q-header-list=&q-url-param-list=&q-signature=f570200e0a18af2ff2359d03a45b4f738f1b2d1d'
    },
    {
      subCategoryCode: 'C5',
      isFullScore: false,
      title: '驾驶行为报告',
      titleEn: 'APP veicle usage report',
      describe: ['可通过手机APP完成评估驾驶行为报告，助您更好驾控'],
      describeEn: ['Complete the evaluation of driving behavior report through the mobile APP to help you better drive'],
      imgSrc: require('./imgs/highlight3/img3.png'),
      videoUrl: 'https://ei-scrmsales-cos-uat-1316407986.cos.ap-shanghai.myqcloud.com/mot-tdr/Connectivity/Video/APP%20veicle%20usage%20report.mp4?q-sign-algorithm=sha1&q-ak=AKIDcBLpGrVEveQEBKaq1ER7kLC604ESl7ne&q-sign-time=1724227477;2588141077&q-key-time=1724227477;2588141077&q-header-list=&q-url-param-list=&q-signature=9aec251f91bb03d3b0601d64f1d5d0e5585b0ee3'
    },
    {
      subCategoryCode: 'C6',
      isFullScore: false,
      title: '车辆健康报告',
      titleEn: 'APP vehicle health report',
      describe: ['可通过手机APP完成车辆自检，车辆状态了如指掌'],
      describeEn: ['Complete vehicle self-inspection through mobile APP, and know the vehicle status well'],
      imgSrc: require('./imgs/highlight3/img2.png'),
      videoUrl: 'https://ei-scrmsales-cos-uat-1316407986.cos.ap-shanghai.myqcloud.com/mot-tdr/Connectivity/Video/APP%20vehicle%20health%20report.mp4?q-sign-algorithm=sha1&q-ak=AKIDcBLpGrVEveQEBKaq1ER7kLC604ESl7ne&q-sign-time=1724227465;2588141065&q-key-time=1724227465;2588141065&q-header-list=&q-url-param-list=&q-signature=105d079ae2d7dbb7297bf007a4e518a952d89d04'
    },
  ],
  'D': [
    {
      subCategoryCode: 'E1',
      isFullScore: false,
      title: '在线寻车',
      titleEn: 'APP car finder',
      describe: ['车辆位置尽在掌握，手机APP实现快速定位，在线寻车'],
      describeEn: ['The location of the vehicle is mastered, and the mobile APP can realize rapid positioning and online car searching'],
      imgSrc: require('./imgs/highlight4/img1.png'),
      videoUrl: 'https://ei-scrmsales-cos-uat-1316407986.cos.ap-shanghai.myqcloud.com/mot-tdr/Carfree%20Parking/Video/APP%20car%20finder.mp4?q-sign-algorithm=sha1&q-ak=AKIDcBLpGrVEveQEBKaq1ER7kLC604ESl7ne&q-sign-time=1724227199;2588140799&q-key-time=1724227199;2588140799&q-header-list=&q-url-param-list=&q-signature=1f67b56e73b978d18083b38ea89c6d69952716b3'
    },
    {
      subCategoryCode: 'E2',
      isFullScore: false,
      title: '360°全景摄像系统',
      titleEn: 'Topview',
      describe: [
        '车身四周4个高清摄像头，周边路况尽在掌握。',
        '车辆挂入倒挡即可自动开启，辅助泊车。'
      ],
      describeEn: [
        'Four high-definition cameras around the body, the surrounding road conditions are mastered.',
        'When the vehicle is in reverse gear, it can be automatically opened to assist parking'
      ],
      imgSrc: require('./imgs/highlight4/img4.png'),
      videoUrl: 'https://ei-scrmsales-cos-uat-1316407986.cos.ap-shanghai.myqcloud.com/mot-tdr/Carfree%20Parking/Video/Topview.mp4?q-sign-algorithm=sha1&q-ak=AKIDcBLpGrVEveQEBKaq1ER7kLC604ESl7ne&q-sign-time=1724227237;2588140837&q-key-time=1724227237;2588140837&q-header-list=&q-url-param-list=&q-signature=ce08f7a0b907a86711f60652284a0e4f225005f1'
    },
    {
      subCategoryCode: 'E3',
      isFullScore: false,
      title: 'IPA智能泊车辅助',
      titleEn: 'IPA',
      describe: ['IPA智能泊车，自动识别车位，一键泊入'],
      describeEn: ['Automatic identification of parking Spaces, one-click parking'],
      imgSrc: require('./imgs/highlight4/img2.png'),
      videoUrl: 'https://ei-scrmsales-cos-uat-1316407986.cos.ap-shanghai.myqcloud.com/mot-tdr/Carfree%20Parking/Video/IPA.mp4?q-sign-algorithm=sha1&q-ak=AKIDcBLpGrVEveQEBKaq1ER7kLC604ESl7ne&q-sign-time=1724227212;2588140812&q-key-time=1724227212;2588140812&q-header-list=&q-url-param-list=&q-signature=df2b928f54ca35d3c7d7d76e0135a0078efaf41c'
    },
    {
      subCategoryCode: 'E4',
      isFullScore: false,
      title: 'RPA遥控泊车辅助',
      titleEn: 'RPA',
      describe: ['RPA遥控泊车，手机APP控制，泊入泊出轻松在手'],
      describeEn: ['Mobile APP control, docking in and out easily in hand'],
      imgSrc: require('./imgs/highlight4/img3.png'),
      videoUrl: 'https://ei-scrmsales-cos-uat-1316407986.cos.ap-shanghai.myqcloud.com/mot-tdr/Carfree%20Parking/Video/RPA.mp4?q-sign-algorithm=sha1&q-ak=AKIDcBLpGrVEveQEBKaq1ER7kLC604ESl7ne&q-sign-time=1724227221;2588140821&q-key-time=1724227221;2588140821&q-header-list=&q-url-param-list=&q-signature=6527501972ea9979fdf3e3e9c5f9d413e2beb512'
    },
    {
      subCategoryCode: 'E5',
      isFullScore: false,
      title: 'TPA记忆泊车辅助',
      titleEn: 'TPA',
      describe: ['TPA记忆泊车，常用泊位自主泊车'],
      describeEn: ['Common parking space autonomous parking'],
      imgSrc: require('./imgs/highlight4/img5.png'),
      videoUrl: 'https://ei-scrmsales-cos-uat-1316407986.cos.ap-shanghai.myqcloud.com/mot-tdr/Carfree%20Parking/Video/TPA.mp4?q-sign-algorithm=sha1&q-ak=AKIDcBLpGrVEveQEBKaq1ER7kLC604ESl7ne&q-sign-time=1724227229;2588140829&q-key-time=1724227229;2588140829&q-header-list=&q-url-param-list=&q-signature=95c497843a781a20669b1045ba1f516bfaf62c9c'
    },
  ],
  'E': [
    {
      subCategoryCode: 'A1',
      isFullScore: false,
      title: '高性能四驱(Max高性能专属)',
      titleEn: '0-100KM/H Acceleration',
      describe: [
        '电机峰值功率前80KW/后170KW，输出动力澎湃。',
        '电机峰值扭矩前162Nm/后310Nm，起步轻盈足力。',
        '百公里加速仅需5.6秒。'
      ],
      describeEn: [
        'The peak power of the motor is 80KW/ 170KW, and the output power is surging.',
        'Motor peak torque before 162Nm/ after 310Nm, start light foot force.',
        '100 km in 5.6 seconds'
      ],
      imgSrc: require('./imgs/highlight5/img1.png'),
      videoUrl: 'https://ei-scrmsales-cos-uat-1316407986.cos.ap-shanghai.myqcloud.com/mot-tdr/Dynamic%20Driving/0-100kmh%20acceleration.mp4?q-sign-algorithm=sha1&q-ak=AKIDcBLpGrVEveQEBKaq1ER7kLC604ESl7ne&q-sign-time=1724227517;2588141117&q-key-time=1724227517;2588141117&q-header-list=&q-url-param-list=&q-signature=5499eeb789fbd6f835f03a71cfc02296eda25d2c'
    },
    {
      subCategoryCode: 'A2',
      isFullScore: false,
      title: '4.7m 最小转弯半径',
      titleEn: '4.7m turning radius',
      describe: [
        'MEB平台独特前舱设计，前轮大幅转向，尽情施展。',
        '4.7m 最小转弯半径，调头转向，尽享灵动驾控。'
      ],
      describeEn: [
        'MEB platform unique front cabin design, front wheel steering, enjoy the driving.',
        '4.7m minimum turning radius, turn around, enjoy smart driving control'
      ],
      imgSrc: require('./imgs/highlight5/img3.png'),
      videoUrl: 'https://ei-scrmsales-cos-uat-1316407986.cos.ap-shanghai.myqcloud.com/mot-tdr/Dynamic%20Driving/4.7m%20turning%20radius.mp4?q-sign-algorithm=sha1&q-ak=AKIDcBLpGrVEveQEBKaq1ER7kLC604ESl7ne&q-sign-time=1724227538;2588141138&q-key-time=1724227538;2588141138&q-header-list=&q-url-param-list=&q-signature=46a0f03c92d047b40624571ef6e66e8d077fed3e'
    },
    {
      subCategoryCode: 'A3',
      isFullScore: false,
      title: 'DCC自适应底盘调节系统(Max高性能专属)',
      titleEn: 'DCC',
      describe: [
        '根据道路情况、驾驶员操作、车辆运动姿态等信息，实时动态调整减振器阻尼。',
        '15档个性化可调。'
      ],
      describeEn: [
        'According to road conditions, driver operation, vehicle motion attitude and other information, real-time dynamic adjustment of shock absorber damping.',
        '15 personalized adjustable'
      ],
      imgSrc: require('./imgs/highlight5/img2.png'),
      videoUrl: 'https://ei-scrmsales-cos-uat-1316407986.cos.ap-shanghai.myqcloud.com/mot-tdr/Dynamic%20Driving/DCC.mp4?q-sign-algorithm=sha1&q-ak=AKIDcBLpGrVEveQEBKaq1ER7kLC604ESl7ne&q-sign-time=1724227528;2588141128&q-key-time=1724227528;2588141128&q-header-list=&q-url-param-list=&q-signature=6f7b86c3766a2f0c14e52663f5bc97ccdc47957b'
    },
    {
      subCategoryCode: 'A2',
      isFullScore: false,
      title: '可变转向系统',
      titleEn: 'Progressive steering',
      describe: [
        '小角度转向舒适敏捷。',
        '大角度转向精准操控。'
      ],
      describeEn: [
        'Small Angle steering comfortable and agile',
        'Large Angle steering precision control'
      ],
      imgSrc: require('./imgs/highlight5/img4.png'),
      videoUrl: 'https://ei-scrmsales-cos-uat-1316407986.cos.ap-shanghai.myqcloud.com/mot-tdr/Dynamic%20Driving/Progressive%20steering.mp4?q-sign-algorithm=sha1&q-ak=AKIDcBLpGrVEveQEBKaq1ER7kLC604ESl7ne&q-sign-time=1724227547;2588141147&q-key-time=1724227547;2588141147&q-header-list=&q-url-param-list=&q-signature=d2e847cdc3fbf02142408be1f763c6764d91a130'
    },
  ]
}
export const radarLabel = {
  'A': '辅助驾驶',
  'B': '智能座舱',
  'C': '互联性',
  'D': '无忧泊车',
  'E': '动态驾驶',
}
export const radarLabelEn = {
  'A': 'Assisted Driving',
  'B': 'Smart Cockpit',
  'C': 'Connectivity',
  'D': 'Carefree Parking',
  'E': 'Dynamic Driving',
}
export const moreInfos = [
  {
    describe: '选配方案或立即下单',
    describeEn: 'Select a Plan or Order Now',
    btnName: '订购选配',
    btnNameEn: 'Configuration',
    imgSrc: require('./imgs/more1.png'),
    jsBridgeMethod: 'jumpFlutterPageByRouteKey',
    jsBridgeParams: 'app_configuration.carConfigurationPage', // 1以我的位置为中心 2以人车的位置为中心   3以车的位置为中心
    wxUrl: '/pages/carConfiguration/carConfiguration'
  },
  {
    describe: '金标专属生活方式',
    describeEn: 'Gold Standard Lifestyle',
    btnName: '车主活动',
    btnNameEn: 'Events',
    imgSrc: require('./imgs/more2.png'),
    jsBridgeMethod: 'jumpFlutterPageByRouteKey',
    jsBridgeParams: 'keyEventListPage', // 1以我的位置为中心 2以人车的位置为中心   3以车的位置为中心
    wxUrl: '/community/pages/allActivities/allActivities'
  },
  {
    describe: '查看您附近的充电站点',
    describeEn: 'Locate Nearby Charging Stations',
    btnName: '充电站点',
    btnNameEn: 'Charging Station',
    imgSrc: require('./imgs/more3.png'),
    jsBridgeMethod: 'openChargingHome',
    jsBridgeParams: 1, // 1以我的位置为中心 2以人车的位置为中心   3以车的位置为中心
    wxUrl: {
      dev: '/community/pages/communityDetail/communityDetail?id=200553319880654848',
      sit: '/community/pages/communityDetail/communityDetail?id=200553319880654848',
      uat: '/community/pages/communityDetail/communityDetail?id=200553319880654848',
      pro: '/community/pages/communityDetail/communityDetail?id=257482326710353920'
    }
  },
  {
    describe: '获取心仪车型金融政策',
    describeEn: 'Get Financial Policies for Your Desired Model',
    btnName: '金融政策',
    btnNameEn: 'Financial Policies',
    imgSrc: require('./imgs/more4.png'),
    appUrl: {
      dev: 'https://uat-oneapp-web.idux-vw.com/financialCalculator',
      sit: 'https://uat-oneapp-web.idux-vw.com/financialCalculator',
      uat: 'https://uat-oneapp-web.idux-vw.com/financialCalculator',
      pro: 'https://m.idux-vw.com/financialCalculator'
    },
    wxUrl: '/pages/financialCalculator/financialCalculator'
  }
]
